export const FETCH_ALL_PLINKS = `query ($status: String, $pageSize: Int, $pageNumber: Int) {
    list_all_payment_links(page_size: $pageSize, page_number: $pageNumber, status: $status){
      message
      data {
        _id
        plink_id
        url
        amount
        currency
        created_at
        status
        expiry
        payment_for
        user_id {
          _id
          name
          email
        }
      }
      hasMore
    }
  }
  `;

export const CREATE_NEW_PLINK = `mutation ($expiry: String, $paymentFor: String, $amount: Float!, $network: String, $notes: [create_payment_link_notes]) {
  create_payment_link(payment_for: $paymentFor, amount: $amount, expiry: $expiry, network: $network, notes: $notes) {
    message
    link
  }
}
`;

export const FETCH_PLINK_DETAILS = `query ($plinkId: String!) {
  list_payment_link_details(plink_id: $plinkId) {
    message
    data {
      _id
      plink_id
      url
      amount
      currency
      created_at
      status
      expiry
      payment_for
      user_id {
        _id
        name
        email
      }
      txn_id {
        txn_id
        txn_signature
        status
      }
      wallet_id {
        wallet_id
      }
    }
  }
}
`;

export const SHARE_PLINK = `query ($email: String!, $plinkId: String!) {
  share_payment_link_to_email(plink_id: $plinkId, email: $email) {
    message
  }
}
`;
