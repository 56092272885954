/*eslint-disable*/
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, CircularProgress, Modal, Stack, TextField, Typography } from '@mui/material';
import { resetApiKey, generateApiKey } from 'store/actions';
import Page from '../../components/Page';
import CopyToClipboard from 'react-copy-to-clipboard';
import Iconify from 'components/Iconify';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 380,
  bgcolor: 'background.paper',
  border: 'none',
  borderRadius: '10px',
  boxShadow: 24,
  outline: 0,
  p: 4,
};

export default function ApiKey() {
  const dispatch = useDispatch();
  const [copied, setCopied] = useState(false);
  const [open, setOpen] = useState(false);
  const [isApiKeyAvailable, setIsApiKeyAvailable] = useState(false);

  const { apiKey, profile, loading } = useSelector(({ merchant: { apiKey, profile }, loading }) => ({
    apiKey,
    profile,
    loading
  }));

  useEffect(() => {
    if (profile.api_key) {
      setIsApiKeyAvailable(true);
    }
    dispatch(resetApiKey());
  }, []);

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    dispatch(resetApiKey());
    setOpen(false);
    setIsApiKeyAvailable(true);
  }

  const handleGenerateBtn = () => {
    dispatch(resetApiKey());
    dispatch(generateApiKey());
    setIsApiKeyAvailable(false);
  }

  return (
    <Page title="My Account: Api Key">
      <Stack direction="row" spacing={1}>
        <TextField title='Api Key' size='small' value={isApiKeyAvailable ? "xxxxxxxxxx" : "-"} disabled />
        <Button style={{ whiteSpace: "nowrap", padding: "0 20px" }} variant='contained' size='small' onClick={handleOpen}>
          {isApiKeyAvailable ? "Regenerate" : "Generate"} Api Key
        </Button>
      </Stack>

      <Modal
        open={open}
      // onClose={handleClose}
      >
        <Box sx={style}>
          <Stack spacing={1}>
            {isApiKeyAvailable ?
              <>
                <Typography variant="subtitle1" sx={{ color: 'text.primary' }}>
                  Are you sure to regenerate Api Key
                </Typography>
                <br />
                <Stack direction="row" spacing={1}>
                  <Button variant='outlined' fullWidth onClick={handleClose}>
                    Cancel
                  </Button>
                  <Button variant='contained' fullWidth onClick={handleGenerateBtn}>
                    Regenerate
                  </Button>
                </Stack>
              </> :
              <>
                <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                  Your API Key is
                </Typography>
                <Typography variant="body1" sx={{ color: 'text.secondary' }}>
                  {loading ? <CircularProgress /> : apiKey}
                </Typography>
                <Typography variant="body2" sx={{ color: 'text.primary' }}>
                  Warning: Api key shows only once, You won't be able to see it again. Copy and save it safe.
                </Typography>
                <Stack direction="row" spacing={1}>
                  <Button variant='outlined' fullWidth onClick={handleClose}>
                    Close
                  </Button>
                  <CopyToClipboard text={apiKey} onCopy={() => setCopied(true)}>
                    <Button variant='contained' fullWidth startIcon={<Iconify icon="eva:copy-fill" />}>
                      {copied ? "Copied" : "Copy"}
                    </Button>
                  </CopyToClipboard>
                </Stack>
              </>}
          </Stack>
        </Box>
      </Modal>
    </Page>
  );
}
