export const FETCH_ALL_SETTLEMENTS = `query ($status: String, $pageSize: Int, $pageNumber: Int) {
  list_all_settlements(page_size: $pageSize, page_number: $pageNumber, status: $status){
    message
    data{
      _id
      settlement_id
      amount
      currency
      created_at
      status
      chain_network
    }
  }
}
`;

export const FETCH_SETTLEMENT_DETAILS = `query ($settlementId: String!){
  list_settlement_details(settlement_id: $settlementId){
    message
    data{
      _id
      settlement_id
      amount
      currency
      created_at
      status
      chain_network
      failure_reason
      txn_id {
        txn_id
        txn_signature
        status
        type
        failure_reason
        amount
        currency
        chain_network
        user_id {
          _id
          name
          email
        }
      }
    }
  }
}`;