import React from "react";
import { useNavigate } from "react-router-dom";
import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import { Icon } from "@iconify/react";

const style = {
  width: "auto",
  bgcolor: "background.paper",
  border: "none",
  outline: "none",
  borderRadius: "10px",
  boxShadow: 24,
  p: 4,
};

export default function NewPageModalContent({ dispatch, handleClose }) {
  const navigate = useNavigate();

  const handleGoBtn = () => {
    handleClose();
    navigate('/dashboard/payment-pages/custom');
  }

  return (
    <>
      <Grid container justifyContent="center" style={{ outline: "none" }}>
        <Grid item lg={4} md={6} xs={11}>
          <Box sx={style}>
            <Typography variant="h4" align="center" gutterBottom>
              Create New Payment Page
            </Typography>
            <br />

            <Box sx={{ width: "100%", border: "1px solid #ccc", borderRadius: "10px", padding: "10px" }}>
              <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center">
                <Stack sx={{width: "50px"}}>
                <Icon icon="uil:create-dashboard" width="40" />
                </Stack>
                <Stack direction="column" sx={{ width: "100%" }}>
                  <Typography variant="h5">Create your Own</Typography>
                  <Typography variant="body2" sx={{color: "text.secondary"}}>
                    Got your own idea? Start with a custom template.
                  </Typography>
                </Stack>
                <Button size='large' variant="contained" onClick={handleGoBtn}>Go</Button>
              </Stack>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}
