import React from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import * as Yup from "yup";
// form
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// @mui
import { Stack } from "@mui/material";
import { LoadingButton } from "@mui/lab";
// components
import { FormProvider, RHFTextField } from "../../components/hook-form";
import { sharePageLinkToEmail } from "store/actions";

const style = {
    width: "auto",
    bgcolor: "background.paper",
    border: "none",
    outline: "none",
    borderRadius: "10px",
    boxShadow: 24,
    p: 4,
};

function ShareLinkModalContent({ id, dispatch, handleClose }) {
    const RegisterSchema = Yup.object().shape({
        email: Yup.string().email("Invalid email address").required("email address is required"),
    });

    const defaultValues = {
        email: "",
        name: "",
    };

    const methods = useForm({
        resolver: yupResolver(RegisterSchema),
        defaultValues,
    });

    const {
        handleSubmit,
        formState: { isSubmitting },
    } = methods;

    const onSubmit = (data) => {
        data.pageId = id;
        handleClose();
        dispatch(sharePageLinkToEmail(data));
    };

    return (
        <>
            <Grid container justifyContent="center" style={{ outline: "none" }}>
                <Grid item lg={4} md={6} xs={11}>
                    <Box sx={style}>
                        <Typography variant="h4" align="center" gutterBottom>
                            Share Payment Page
                        </Typography>

                        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                            <Stack spacing={3}>
                                <RHFTextField name="email" label="Email address" />
                                <RHFTextField name="name" label="Email name of the receiver" />

                                <LoadingButton
                                    fullWidth
                                    size="large"
                                    type="submit"
                                    variant="contained"
                                    loading={isSubmitting}
                                >
                                    Share Page
                                </LoadingButton>
                                <Button onClick={handleClose}>Cancel</Button>
                            </Stack>
                        </FormProvider>
                    </Box>
                </Grid>
            </Grid>
        </>
    );
}

export default ShareLinkModalContent;
