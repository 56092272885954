/*eslint-disable*/
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import CopyToClipboard from 'react-copy-to-clipboard';
import moment from 'moment';
// material
import { Container, Typography, Box, Button, Grid, Modal } from '@mui/material';
import { styled } from '@mui/material/styles';
// components
import Page from '../../components/Page';
import Iconify from 'components/Iconify';
import ShareLinkModalContent from './shareLinkModalContent';
// store
import { resetPlinkDetailsData, listPlinkDetails } from 'store/actions';

// ----------------------------------------------------------------------

const AccountStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: theme.palette.grey[500_12],
}));

// ----------------------------------------------------------------------

export default function PaymentLinkDetails() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const [open, setOpen] = useState(false);
  const [copied, setCopied] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const { plinkDetails } = useSelector(({ plinks: { plinkDetails } }) => ({
    plinkDetails,
  }));

  useEffect(() => {
    dispatch(resetPlinkDetailsData());
    dispatch(listPlinkDetails({ plinkId: id }));
  }, []);

  const handleBack = () => {
    navigate('/dashboard/payment-links')
  }

  return (
    <Page title="Payment Links: Details">
      <Container>
        {plinkDetails ?
          <>
            <Box sx={{ mb: 1 }}>
              <Button sx={{ mb: 1 }} onClick={handleBack} startIcon={<Iconify icon="eva:arrow-back-fill" />} />
              <AccountStyle>
                <Grid container spacing={2}>
                  <Grid item lg={12} xs={12}>
                    <Box sx={{ overflow: "hidden" }}>
                      <Typography variant="subtitle1" sx={{ color: 'text.primary' }}>
                        {plinkDetails?.url ? plinkDetails?.url.replace("https://", "") : "---"}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item>
                    <CopyToClipboard text={plinkDetails?.url} onCopy={() => setCopied(true)}>
                      <Button variant="outlined" startIcon={<Iconify icon="eva:copy-fill" />}>
                        {copied ? "Copied" : "Copy"} Payment Link
                      </Button>
                    </CopyToClipboard>
                  </Grid>
                  <Grid item>
                    <Button variant="contained" onClick={handleOpen} startIcon={<Iconify icon="eva:share-fill" />}>
                      Share Payment Link
                    </Button>
                  </Grid>
                </Grid>
              </AccountStyle>
            </Box>

            <Box sx={{ mb: 5 }}>
              <AccountStyle>
                <Box>
                  <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                    Payment for
                  </Typography>
                  <Typography variant="body1" sx={{ color: 'text.secondary' }}>
                    {plinkDetails.payment_for}
                  </Typography>
                  <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                    Amount
                  </Typography>
                  <Typography variant="body1" sx={{ color: 'text.secondary' }}>
                    {plinkDetails.amount ? plinkDetails.amount : "-"}{" "}
                    {plinkDetails.currency ? plinkDetails.currency.toUpperCase() : "-"}
                  </Typography>
                  <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                    Status
                  </Typography>
                  <Typography variant="body1" sx={{ color: 'text.secondary' }}>
                    {plinkDetails.status ? plinkDetails.status : "-"}
                  </Typography>
                  <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                    Expiry
                  </Typography>
                  <Typography variant="body1" sx={{ color: 'text.secondary' }}>
                    { plinkDetails?.expiry === 0 ? "No expiry" :
                      moment.unix(plinkDetails?.expiry).format("DD-MM-YYYY HH:mm")}
                  </Typography>
                  <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                    TXN ID
                  </Typography>
                  <Typography variant="body1" sx={{ color: 'text.secondary' }}>
                    {plinkDetails?.txn_id?.txn_id ? plinkDetails?.txn_id?.txn_id : "-"}
                  </Typography>
                </Box>
              </AccountStyle>
            </Box>
          </>
          : null}
      </Container>

      <Modal
        open={open}
        onClose={handleClose}
        style={{
          width: "100%",
          display: "grid",
          placeItems: "center",
          overflow: "scroll",
          margin: "40px 0"
        }}
      >
        <>
          <ShareLinkModalContent id={id} dispatch={dispatch} handleClose={handleClose} />
        </>
      </Modal>
    </Page>
  );
}
