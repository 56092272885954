import {
    LIST_ALL_PAYMENT_PAGES_DATA,
    RESET_PAYMENT_PAGES_DATA,
    LIST_PAGE_DETAILS_DATA,
    RESET_PAGE_DETAILS_DATA,
    LIST_ALL_PAGE_PAYMENTS_DATA,
    RESET_PAGE_PAYMENTS_DATA,
} from "../types";

const INITIAL_STATE = {
    list: [],
    showViewMore: false,
    pageNumber: 1,
    pageSize: 10,
    pageDetails: {},
    pagePayments: {
        list: [],
        showViewMore: false,
        pageNumber: 1,
        pageSize: 10,
    },
};

const paymentPagesReducer = (state = INITIAL_STATE, { type, payload }) => {
    switch (type) {
        case LIST_ALL_PAYMENT_PAGES_DATA:
            const data = { pageSize: 10, ...payload };
            return {
                ...state,
                ...data,
            };

        case RESET_PAYMENT_PAGES_DATA:
            return INITIAL_STATE;

        case LIST_PAGE_DETAILS_DATA:
            return {
                ...state,
                pageDetails: payload,
            };

        case RESET_PAGE_DETAILS_DATA:
            return {
                ...state,
                pageDetails: INITIAL_STATE.pageDetails,
            };

        case LIST_ALL_PAGE_PAYMENTS_DATA:
            const payments = { pageSize: 10, ...payload };
            return {
                ...state,
                pagePayments: payments,
            };

        case RESET_PAGE_PAYMENTS_DATA:
            return {
                ...state,
                pagePayments: INITIAL_STATE.pagePayments,
            };

        default:
            return state;
    }
};

export default paymentPagesReducer;
