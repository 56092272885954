import { post } from "services";
import { FETCH_ALL_SETTLEMENTS, FETCH_SETTLEMENT_DETAILS } from "graphql";
import { 
    TOGGLE_LOADING, 
    LIST_SETTLEMENTS_DATA, 
    LIST_SETTLEMENT_DETAILS_DATA, 
    RESET_SETTLEMENTS_LIST_DATA, 
    RESET_SETTLEMENT_DETAILS_DATA, 
} from "../types";

export const listAllSettlements = (requestData) => {
    return async (dispatch, getState) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });
        const { pageSize, pageNumber, list } = getState().settlements;

        let apiResponse = await post("", {
            query: FETCH_ALL_SETTLEMENTS,
            variables: {
                pageSize: pageSize,
                pageNumber: pageNumber,
                status: requestData?.status ?? "",
            },
        });

        if (apiResponse.data.customStatus) {
            let {
                list_all_settlements: { data, hasMore },
            } = apiResponse.data.data;

            let updatedList = [...list, ...data];

            dispatch({
                type: LIST_SETTLEMENTS_DATA,
                payload: {
                    list: updatedList,
                    showViewMore: hasMore,
                    pageNumber: pageNumber + 1,
                },
            });
        }

        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};

export const resetSettlemenetsData = () => {
    return async (dispatch) => {
        dispatch({ type: RESET_SETTLEMENTS_LIST_DATA });
    };
};

export const listSettlementDetails = (requestData) => {
    return async (dispatch) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });

        let apiResponse = await post("", {
            query: FETCH_SETTLEMENT_DETAILS,
            variables: requestData,
        });

        if (apiResponse.data.customStatus) {
            let {
                list_settlement_details: { data },
            } = apiResponse.data.data;

            dispatch({
                type: LIST_SETTLEMENT_DETAILS_DATA,
                payload: data,
            });
        }

        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};

export const resetSettlementDetailsData = () => {
    return async (dispatch) => {
        dispatch({ type: RESET_SETTLEMENT_DETAILS_DATA });
    };
};