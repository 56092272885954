/*eslint-disable*/
import React from 'react';
import { Container } from '@mui/material';
import Page from '../../components/Page';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Profile from './Profile';
import ApiKey from './ApiKey';


export default function MyAccount() {
  const [value, setValue] = React.useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Page title="My Account">
      <Container>
        <Box sx={{ width: '100%', typography: 'body1' }}>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList onChange={handleChange} aria-label="lab API tabs example">
                <Tab label="Profile" value="1" />
                <Tab label="Api Key" value="2" />
              </TabList>
            </Box>
            <TabPanel style={{padding: "20px 0px"}} value="1"><Profile /></TabPanel>
            <TabPanel style={{padding: "20px 0px"}} value="2"><ApiKey /></TabPanel>
          </TabContext>
        </Box>
      </Container>
    </Page>
  );
}
