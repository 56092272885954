import React, { useEffect, useState } from "react";
import Page from "components/Page";
import Iconify from "components/Iconify";
import {
    Card,
    Table,
    Stack,
    Button,
    TableRow,
    TableBody,
    TableCell,
    Container,
    Typography,
    TableContainer,
    Modal,
    Tooltip,
} from "@mui/material";
import Scrollbar from "../../components/Scrollbar";
import SearchNotFound from "../../components/SearchNotFound";
import { UserListHead } from "../../sections/@dashboard/user";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import NewPageModalContent from "./newPageModalContent";
import { listAllPaymentPages, resetPaymentPagesData } from "store/actions";
import moment from "moment";
import CopyToClipboard from "react-copy-to-clipboard";

const TABLE_HEAD = [
    { id: "url", label: "Link", alignRight: false },
    { id: "title", label: "Title", alignRight: false },
    { id: "status", label: "Status", alignRight: false },
    { id: "created_at", label: "Created At", alignRight: false },
    { id: "" },
];

// ----------------------------------------------------------------------

export default function PaymentPages() {
    const [selected, setSelected] = useState([]);
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [hoveredLink, setHoveredLink] = useState("");
    const [copied, setCopied] = useState(false);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { paymentPagesData, showViewMore } = useSelector(({ paymentPages: { list, showViewMore } }) => ({
        paymentPagesData: list,
        showViewMore,
    }));

    useEffect(() => {
        dispatch(resetPaymentPagesData());
        fetchMoreData();
    }, []);

    const fetchMoreData = () => {
        dispatch(listAllPaymentPages());
    };

    const handleHover = (data = "") => {
        setHoveredLink(data);
    };

    const filteredUsers = paymentPagesData;

    const isUserNotFound = paymentPagesData.length === 0;

    return (
        <Page title="Payment Pages">
            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Payment Pages
                    </Typography>
                    <Button variant="contained" onClick={handleOpen} startIcon={<Iconify icon="eva:plus-fill" />}>
                        Create Payment Page
                    </Button>
                </Stack>

                <Card>
                    <Scrollbar>
                        <TableContainer sx={{ minWidth: 800 }}>
                            <Table>
                                <UserListHead headLabel={TABLE_HEAD} rowCount={paymentPagesData.length} />
                                <TableBody>
                                    {filteredUsers.map((row, index) => {
                                        const { url, title, status, created_at, page_id } = row;
                                        const isItemSelected = selected.indexOf(url) !== -1;

                                        return (
                                            <TableRow
                                                hover
                                                key={index}
                                                tabIndex={-1}
                                                role="checkbox"
                                                selected={isItemSelected}
                                                aria-checked={isItemSelected}
                                                onClick={() => navigate(`/dashboard/page-details/${page_id}`)}
                                            >
                                                <TableCell padding="checkbox">
                                                    {/* <Checkbox checked={isItemSelected} onChange={(event) => handleClick(event, url)} /> */}
                                                </TableCell>
                                                <TableCell align="left">
                                                    <Typography variant="subtitle2" noWrap>
                                                        <div
                                                            onMouseOver={() => handleHover(url)}
                                                            onMouseOut={() => setCopied(false)}
                                                        >
                                                            <>
                                                                <CopyToClipboard
                                                                    text={hoveredLink}
                                                                    onCopy={() => setCopied(true)}
                                                                >
                                                                    {hoveredLink === url ? (
                                                                        <Tooltip
                                                                            title={copied ? "copied" : "click to copy"}
                                                                        >
                                                                            <div>{url.replace("https://", "")}</div>
                                                                        </Tooltip>
                                                                    ) : (
                                                                        <div>{url.replace("https://", "")}</div>
                                                                    )}
                                                                </CopyToClipboard>
                                                            </>
                                                        </div>
                                                    </Typography>
                                                </TableCell>
                                                <TableCell align="left">
                                                    <Typography noWrap>{title}</Typography>
                                                </TableCell>
                                                <TableCell align="left">{status}</TableCell>
                                                <TableCell align="left">
                                                    {moment.unix(created_at).format("DD-MM-YYYY")}
                                                </TableCell>
                                                <TableCell align="right"> {/* <UserMoreMenu id={plink_id}/> */} </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>

                                {isUserNotFound && (
                                    <TableBody>
                                        <TableRow>
                                            <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                                                <SearchNotFound searchQuery={" "} />
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                )}
                            </Table>
                        </TableContainer>
                    </Scrollbar>
                    {showViewMore && (
                        <Stack justifyContent="center">
                            <Button onClick={fetchMoreData}>Show more</Button>
                        </Stack>
                    )}
                </Card>
            </Container>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                style={{
                    width: "100%",
                    display: "grid",
                    placeItems: "center",
                    overflow: "scroll",
                    margin: "20px 0",
                }}
            >
                <>
                    <NewPageModalContent dispatch={dispatch} handleClose={handleClose} />
                </>
            </Modal>
        </Page>
    );
}
