import { toast } from "react-toastify";
import { post } from "services";
import {
    LIST_ALL_PAYMENT_PAGES_DATA,
    RESET_PAYMENT_PAGES_DATA,
    TOGGLE_LOADING,
    LIST_PAGE_DETAILS_DATA,
    RESET_PAGE_DETAILS_DATA,
    RESET_PAGE_PAYMENTS_DATA,
    LIST_ALL_PAGE_PAYMENTS_DATA,
} from "../types";
import {
    FETCH_ALL_PAYMENT_PAGES,
    FETCH_PAYMENT_PAGE_DETAILS,
    SHARE_PAGE_LINK_TO_EMAIL,
    FETCH_ALL_PAGE_PAYMENTS,
} from "graphql";

export const listAllPaymentPages = (requestData) => {
    return async (dispatch, getState) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });
        const { pageSize, pageNumber, list } = getState().paymentPages;

        let apiResponse = await post("", {
            query: FETCH_ALL_PAYMENT_PAGES,
            variables: {
                pageSize: pageSize,
                pageNumber: pageNumber,
                status: requestData?.status ?? "",
            },
        });

        if (apiResponse.data.customStatus) {
            let {
                list_all_payment_pages: { data, hasMore },
            } = apiResponse.data.data;

            let updatedList = [...list, ...data];

            dispatch({
                type: LIST_ALL_PAYMENT_PAGES_DATA,
                payload: {
                    list: updatedList,
                    showViewMore: hasMore,
                    pageNumber: pageNumber + 1,
                },
            });
        }

        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};

export const resetPaymentPagesData = () => {
    return async (dispatch) => {
        dispatch({ type: RESET_PAYMENT_PAGES_DATA });
    };
};

export const resetPaymentPageDetails = () => {
    return async (dispatch) => {
        dispatch({ type: RESET_PAGE_DETAILS_DATA });
    };
};

export const resetPagePaymentsData = () => {
    return async (dispatch) => {
        dispatch({ type: RESET_PAGE_PAYMENTS_DATA });
    };
};

export const listPageDetails = (requestData) => {
    return async (dispatch) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });

        let apiResponse = await post("", {
            query: FETCH_PAYMENT_PAGE_DETAILS,
            variables: requestData,
        });

        if (apiResponse.data.customStatus) {
            let {
                list_payment_page_details: { data },
            } = apiResponse.data.data;

            dispatch({
                type: LIST_PAGE_DETAILS_DATA,
                payload: data,
            });
        }

        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};

export const sharePageLinkToEmail = (requestData) => {
    return async (dispatch) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });

        let apiResponse = await post("", {
            query: SHARE_PAGE_LINK_TO_EMAIL,
            variables: requestData,
        });

        if (apiResponse.data.customStatus) {
            toast.success("Payment Page shared");
        }

        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};

export const listAllPagePayments = (requestData) => {
    return async (dispatch, getState) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });
        const {
            pagePayments: { pageSize, pageNumber, list },
        } = getState().paymentPages;

        let apiResponse = await post("", {
            query: FETCH_ALL_PAGE_PAYMENTS,
            variables: {
                pageSize: pageSize,
                pageNumber: pageNumber,
                status: requestData?.status ?? "",
                pageId: requestData.pageId,
            },
        });

        if (apiResponse.data.customStatus) {
            let {
                list_all_page_payments: { data, hasMore },
            } = apiResponse.data.data;

            let updatedList = [...list, ...data];

            dispatch({
                type: LIST_ALL_PAGE_PAYMENTS_DATA,
                payload: {
                    list: updatedList,
                    showViewMore: hasMore,
                    pageNumber: pageNumber + 1,
                },
            });
        }

        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};
