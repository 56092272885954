/*eslint-disable*/
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
// material
import { Container, Typography, Box, Button, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
// components
import Page from '../../components/Page';
import Iconify from 'components/Iconify';
// store
import { listSettlementDetails, resetSettlementDetailsData } from 'store/actions';
import Label from '../../components/Label';

// ----------------------------------------------------------------------

const AccountStyle = styled('div')(({ theme }) => ({
  // display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: theme.palette.grey[500_12],
  overflowWrap: "break-word"
}));

// ----------------------------------------------------------------------

export default function SettlementDetails() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const { settlementDetails } = useSelector(({ settlements: { settlementDetails } }) => ({
    settlementDetails,
  }));

  useEffect(() => {
    dispatch(resetSettlementDetailsData());
    dispatch(listSettlementDetails({ settlementId: id }));
  }, []);

  const handleBack = () => {
    navigate('/dashboard/settlements')
  }

  return (
    <Page title="Settlements: Details">
      <Container>
        {settlementDetails ?
          <>
            <Box sx={{ mb: 1 }}>
              <Button sx={{ mb: 1 }} onClick={handleBack} startIcon={<Iconify icon="eva:arrow-back-fill" />} />
              <AccountStyle>
                <Grid container spacing={2}>
                  <Grid item lg={12} xs={12}>
                    <Box sx={{ overflow: "hidden" }}>
                      <Typography variant="subtitle1" sx={{ color: 'text.primary' }}>
                        {settlementDetails?.settlement_id ? settlementDetails?.settlement_id : "---"}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </AccountStyle>
            </Box>

            <Box sx={{ mb: 3 }}>
              <AccountStyle>
                <Box>
                  <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                    Amount
                  </Typography>
                  <Typography variant="body1" sx={{ color: 'text.secondary' }}>
                    {settlementDetails.amount ? settlementDetails.amount : "-"}{" "}
                    {settlementDetails.currency ? settlementDetails.currency.toUpperCase() : "-"}
                  </Typography>
                  <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                    Status
                  </Typography>
                  <Typography variant="body1" sx={{ color: 'text.secondary' }}>
                  <Label variant="ghost" color={
                      settlementDetails?.status === 'failed' ? 'error' :
                      settlementDetails?.status === 'success' ? 'success' :
                      settlementDetails?.status === 'pending' ? 'warning' : "default"
                    }>
                      {settlementDetails?.status}
                    </Label>
                    {/* {settlementDetails.status ? settlementDetails.status : "-"} */}
                  </Typography>
                  <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                    Created At
                  </Typography>
                  <Typography variant="body1" sx={{ color: 'text.secondary' }}>
                    {settlementDetails?.created_at ? moment.unix(settlementDetails?.created_at).format("DD-MM-YYYY HH:mm") : "-"}
                  </Typography>
                  <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                    Failure Reason
                  </Typography>
                  <Typography variant="body1" sx={{ color: 'text.secondary' }}>
                    {settlementDetails?.failure_reason ? settlementDetails?.failure_reason : "-"}
                  </Typography>
                </Box>
              </AccountStyle>
            </Box>

            <Box sx={{ mb: 5 }}>
              <AccountStyle>
                <Box>
                  <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                    Transaction ID
                  </Typography>
                  <Typography variant="body1" sx={{ color: 'text.secondary' }}>
                    {settlementDetails?.txn_id?.txn_id ? settlementDetails?.txn_id?.txn_id : "-"}
                  </Typography>
                  <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                    Transaction Signature
                  </Typography>
                  <Typography variant="body1" sx={{ color: 'text.secondary' }}>
                    {settlementDetails?.txn_id?.txn_signature ? settlementDetails?.txn_id?.txn_signature : "-"}
                  </Typography>
                  <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                    Type
                  </Typography>
                  <Typography variant="body1" sx={{ color: 'text.secondary' }}>
                    {settlementDetails?.txn_id?.type ? settlementDetails?.txn_id?.type : "-"}
                  </Typography>
                  <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                    Status
                  </Typography>
                  <Typography variant="body1" sx={{ color: 'text.secondary' }}>
                    {settlementDetails?.txn_id?.status ? settlementDetails?.txn_id?.status : "-"}
                  </Typography>
                  {settlementDetails?.txn_id?.status === "failed" ?
                    <>
                      <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                        Failure Reason
                      </Typography>
                      <Typography variant="body1" sx={{ color: 'text.secondary' }}>
                        {settlementDetails?.txn_id?.failure_reason ? settlementDetails?.txn_id?.failure_reason : "-"}
                      </Typography>
                    </> : null
                  }
                </Box>
              </AccountStyle>
            </Box>
          </>
          : null}
      </Container>
    </Page>
  );
}
