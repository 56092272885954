export const FETCH_MERCHANT_PROFILE = `query{
    view_merchant_profile{
      message
      data {
        _id
        merchant_id
        email
        name
        phone
        api_key
        business {
          name
          type
          registration_date
          website
          additional_website
          contactus_link
        }
        business_details {
          pan_no
          pan_holder_name
          billing_label
          address
          city
          state
          country
        }
        blockchain_accounts {
          network
          address
        }
      }
    }
  }`;
