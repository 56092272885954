export const UPDATE_BLOCKCHAIN_ACCOUNTS = `mutation ($blockchainAccounts: [update_blockchain_accounts_blockchain_accounts]!) {
  update_blockchain_accounts(blockchain_accounts: $blockchainAccounts) {
    message
  }
}`;

export const GENERATE_API_KEY = `mutation{
  generate_api_key{
    message
    api_key
  }
}`;