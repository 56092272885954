import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Stack } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import { FormProvider, RHFTextField } from '../../../components/hook-form';

import { toggleLoading, loginUser } from 'store/actions';
import { isLoggedIn } from 'utils';

// ----------------------------------------------------------------------

export default function LoginWithOtp({ dispatch, email }) {
  const navigate = useNavigate();
  const [refresh, setRefresh] = useState(false);

  const { isAuthenticated } = useSelector(({ auth: { token } }) => ({
    isAuthenticated: isLoggedIn(token),
  }));

  useEffect(() => {
    dispatch(toggleLoading(false));
    if (isAuthenticated) {
      navigate('/dashboard/app', { replace: true });
    }
  }, [refresh]);

  const LoginSchema = Yup.object().shape({
    otp: Yup.string().required('OTP is required'),
  });

  const defaultValues = {
    otp: '',
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (value) => {
    value.email = email;
    await dispatch(loginUser(value));
    setRefresh(true);
    // window.location.reload();
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        <RHFTextField name="otp" label="Enter OTP" />

      </Stack>

      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
        {/* <RHFCheckbox name="remember" label="Remember me" />
        <Link variant="subtitle2" underline="hover">
          Forgot password?
        </Link> */}
      </Stack>

      <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
        Login
      </LoadingButton>
    </FormProvider>
  );
}
