import { Link as RouterLink, useNavigate } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Link, Container, Typography, Stack } from '@mui/material';
// components
import Page from '../components/Page';
// sections
import { SendOtp } from '../sections/auth/login';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isLoggedIn } from 'utils';
import LoginWithOtp from 'sections/auth/login/LoginWithOtp';
import { toggleLoading } from 'store/actions';
import { Icon } from '@iconify/react';

const HOME_URL = process.env.REACT_APP_HOME_URL;

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [page, setPage] = useState(0);
  const [email, setEmail] = useState("");

  const { isAuthenticated } = useSelector(({ auth: { token } }) => ({
    isAuthenticated: isLoggedIn(token),
  }));

  const handlePage = (value) => {
    setPage(value);
  };

  const updateEmail = (value) => {
    setEmail(value);
  };

  useEffect(() => {
    dispatch(toggleLoading(false));
    if (isAuthenticated) {
      navigate('/dashboard/app', { replace: true });
    }
  }, []);

  return (
    <Page title="Login">
      <RootStyle>
        <Container maxWidth="sm">
          <ContentStyle>
            <Typography variant="h4" align="center" gutterBottom>
              Sign in
            </Typography>

            {/* <Typography align="center" sx={{ color: 'text.secondary', mb: 5 }}>Enter your details below.</Typography> */}

            {!page ? (
              <SendOtp dispatch={dispatch} nextPage={handlePage} updateEmail={updateEmail} />
            ) : (
              <LoginWithOtp dispatch={dispatch} email={email} />
            )}

            <Typography variant="body2" align="center" sx={{ mt: 3 }}>
              Don’t have an account?{' '}
              <Link variant="subtitle2" component={RouterLink} to="/register">
                Get started
              </Link>
            </Typography>
          </ContentStyle>
        </Container>
      </RootStyle>
      <Stack style={{
        position: "fixed",
        bottom: 0,
        textAlign: 'center',
        width: '100%',
        padding: "5px 0",
        backgroundColor: "#fff",
      }}>
        <Link variant="subtitle2">
          <Icon
            width="30"
            cursor='pointer'
            icon="ant-design:home-filled"
            onClick={() => window.open(HOME_URL, "_self")}
          />
        </Link>
      </Stack>
    </Page>
  );
}
