import { LIST_PLINKS_DATA, RESET_PLINKS_LIST_DATA, LIST_PLINK_DETAILS_DATA, RESET_PLINK_DETAILS_DATA } from "../types";

const INITIAL_STATE = {
    list: [],
    showViewMore: false,
    pageNumber: 1,
    pageSize: 10,
    plinkDetails: {},
};

const plinksReducer = (state = INITIAL_STATE, { type, payload }) => {
    switch (type) {
        case LIST_PLINKS_DATA:
            const data = { pageSize: 10, ...payload };
            return {
                ...state,
                ...data,
            };

        case RESET_PLINKS_LIST_DATA:
            return INITIAL_STATE;

        case LIST_PLINK_DETAILS_DATA:
            return {
                ...state,
                plinkDetails: payload,
            };

        case RESET_PLINK_DETAILS_DATA:
            return {
                ...state,
                plinkDetails: INITIAL_STATE.plinkDetails,
            };

        default:
            return state;
    }
};

export default plinksReducer;
