import { post } from "services";
import { toast } from "react-toastify";
import { 
    RESET_MERCHANT_PROFILE, 
    LIST_MERCHANT_PROFILE, 
    TOGGLE_LOADING, 
    LIST_API_KEY_DATA, 
    RESET_API_KEY_DATA 
} from "../types";
import { 
    FETCH_MERCHANT_PROFILE, 
    UPDATE_BLOCKCHAIN_ACCOUNTS, 
    GENERATE_API_KEY 
} from "graphql";

export const resetMerchantProfile = () => {
    return async (dispatch) => {
        dispatch({ type: RESET_MERCHANT_PROFILE });
    };
};

export const listMerchantProfile = (requestData) => {
    return async (dispatch, getState) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });

        let apiResponse = await post("", {
            query: FETCH_MERCHANT_PROFILE,
            // variables: requestData,
        });

        if (apiResponse.data.customStatus) {
            let {
                view_merchant_profile: { data },
            } = apiResponse.data.data;

            dispatch({
                type: LIST_MERCHANT_PROFILE,
                payload: data,
            });
        }

        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};

export const updateBlockchainAccounts = (requestData) => {
    return async (dispatch) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });

        let apiResponse = await post("", {
            query: UPDATE_BLOCKCHAIN_ACCOUNTS,
            variables: requestData,
        });

        if (apiResponse.data.customStatus) {
            dispatch(resetMerchantProfile());
            dispatch(listMerchantProfile());
            toast.success("Blockchain address saved");
        }

        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};

export const generateApiKey = () => {
    return async (dispatch) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });

        let apiResponse = await post("", {
            query: GENERATE_API_KEY,
        });
        
        if (apiResponse.data.customStatus) {
            let {
                generate_api_key: { api_key },
            } = apiResponse.data.data;

            dispatch({
                type: LIST_API_KEY_DATA,
                payload: api_key,
            });
            // toast.success("Api key generated");
        }

        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};

export const resetApiKey = () => {
    return async (dispatch) => {
        dispatch({ type: RESET_API_KEY_DATA });
    };
};