import { post } from "services";
import { RESET_HOMEPAGE_DATA, LIST_HOMEPAGE_REPORTS, TOGGLE_LOADING } from "../types";
import { FETCH_HOMEPAGE_REPORTS } from "graphql";

export const resetHomepageData = () => {
    return async (dispatch) => {
        dispatch({ type: RESET_HOMEPAGE_DATA });
    };
};

export const listHomepageDetails = (requestData) => {
    return async (dispatch, getState) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });

        let apiResponse = await post("", {
            query: FETCH_HOMEPAGE_REPORTS,
            variables: requestData,
        });

        if (apiResponse.data.customStatus) {
            let { homepage_reports } = apiResponse.data.data;

            dispatch({
                type: LIST_HOMEPAGE_REPORTS,
                payload: homepage_reports,
            });
        }

        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};
