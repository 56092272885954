export const SEND_OTP = `mutation ($email: String!) {
    send_otp(email: $email) {
      message
    }
  }
  `;

export const LOGIN_WITH_OTP = `query ($email: String!, $otp: String!) {
    login_with_otp(email: $email, otp: $otp) {
      token
    }
  }
  `;

export const REGISTER_NEW_MERCHANT = `mutation ($email: String!, $name: String!, $phone: String, $businessName: String, $businessType: String, $registrationDate: String, $website: String, $additionalWebsite: String, $contactusLink: String, $panNo: String, $panHolder: String, $billingLabel: String, $address: String, $city: String, $state: String, $country: String) {
  register_new_merchant(email: $email, name: $name, phone: $phone, business: {name: $businessName, type: $businessType, registration_date: $registrationDate, website: $website, additional_website: $additionalWebsite, contactus_link: $contactusLink}, business_details: {pan_no: $panNo, pan_holder_name: $panHolder, billing_label: $billingLabel, address: $address, city: $city, state: $state, country: $country}) {
    message
  }
}
`;
