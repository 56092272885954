import { LIST_SETTLEMENTS_DATA, LIST_SETTLEMENT_DETAILS_DATA, RESET_SETTLEMENTS_LIST_DATA, RESET_SETTLEMENT_DETAILS_DATA } from "../types";

const INITIAL_STATE = {
    list: [],
    showViewMore: false,
    pageNumber: 1,
    pageSize: 10,
    settlementDetails: {},
};

const settlementsReducer = (state = INITIAL_STATE, { type, payload }) => {
    switch (type) {
        case LIST_SETTLEMENTS_DATA:
            const data = { pageSize: 10, ...payload };
            return {
                ...state,
                ...data,
            };

        case RESET_SETTLEMENTS_LIST_DATA:
            return INITIAL_STATE;

        case LIST_SETTLEMENT_DETAILS_DATA:
            return {
                ...state,
                settlementDetails: payload,
            };

        case RESET_SETTLEMENT_DETAILS_DATA:
            return {
                ...state,
                settlementDetails: INITIAL_STATE.settlementDetails,
            };

        default:
            return state;
    }
};

export default settlementsReducer;
