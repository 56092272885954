/*eslint-disable*/
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import CopyToClipboard from "react-copy-to-clipboard";
import moment from "moment";
// material
import { Container, Typography, Box, Button, Grid, Modal } from "@mui/material";
import { styled } from "@mui/material/styles";
// components
import Page from "../../components/Page";
import Iconify from "components/Iconify";
import SharePageModalContent from "./sharePageModalContent";
import PagePayments from "./pagePayments";
// store
import { resetPaymentPageDetails, listPageDetails } from "store/actions";

// ----------------------------------------------------------------------

const AccountStyle = styled("div")(({ theme }) => ({
    // display: "flex",
    // alignItems: "center",
    padding: theme.spacing(2, 2.5),
    borderRadius: Number(theme.shape.borderRadius) * 1.5,
    backgroundColor: theme.palette.grey[500_12],
}));

// ----------------------------------------------------------------------

export default function PaymentPageDetails() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id } = useParams();
    const [open, setOpen] = useState(false);
    const [copied, setCopied] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const { pageDetails } = useSelector(({ paymentPages: { pageDetails } }) => ({
        pageDetails,
    }));

    useEffect(() => {
        dispatch(resetPaymentPageDetails());
        dispatch(listPageDetails({ pageId: id }));
    }, []);

    const handleBack = () => {
        navigate("/dashboard/payment-pages");
    };

    return (
        <Page title="Payment Page: Details">
            <Container>
                {pageDetails ? (
                    <>
                        <Box sx={{ mb: 1 }}>
                            <Button
                                sx={{ mb: 1 }}
                                onClick={handleBack}
                                startIcon={<Iconify icon="eva:arrow-back-fill" />}
                            />
                            <AccountStyle>
                                <Grid container spacing={2}>
                                    <Grid item lg={12} xs={12}>
                                        <Box sx={{ overflow: "hidden" }}>
                                            <Typography variant="subtitle1" sx={{ color: "text.primary" }}>
                                                {pageDetails?.url ? pageDetails?.url.replace("https://", "") : "---"}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item>
                                        <CopyToClipboard text={pageDetails?.url} onCopy={() => setCopied(true)}>
                                            <Button variant="outlined" startIcon={<Iconify icon="eva:copy-fill" />}>
                                                {copied ? "Copied" : "Copy"} Payment Page
                                            </Button>
                                        </CopyToClipboard>
                                    </Grid>
                                    <Grid item>
                                        <Button
                                            variant="contained"
                                            onClick={handleOpen}
                                            startIcon={<Iconify icon="eva:share-fill" />}
                                        >
                                            Share Payment Page
                                        </Button>
                                    </Grid>
                                </Grid>
                            </AccountStyle>
                        </Box>

                        <Box sx={{ mb: 5 }}>
                            <AccountStyle>
                                <Box>
                                    <Typography variant="subtitle2" sx={{ color: "text.primary" }}>
                                        Title
                                    </Typography>
                                    <Typography variant="body1" sx={{ color: "text.secondary" }}>
                                        {pageDetails?.title}
                                    </Typography>
                                    <Typography variant="subtitle2" sx={{ color: "text.primary" }}>
                                        Amount
                                    </Typography>
                                    <Typography variant="body1" sx={{ color: "text.secondary" }}>
                                        {pageDetails?.amount ? pageDetails.amount : "-"}{" "}
                                        {pageDetails?.currency ? pageDetails.currency.toUpperCase() : "-"}
                                    </Typography>
                                    <Typography variant="subtitle2" sx={{ color: "text.primary" }}>
                                        Status
                                    </Typography>
                                    <Typography variant="body1" sx={{ color: "text.secondary" }}>
                                        {pageDetails?.status ? pageDetails.status : "-"}
                                    </Typography>
                                    <Typography variant="subtitle2" sx={{ color: "text.primary" }}>
                                        Expiry
                                    </Typography>
                                    <Typography variant="body1" sx={{ color: "text.secondary" }}>
                                        {pageDetails?.expiry === 0
                                            ? "No expiry"
                                            : moment.unix(pageDetails?.expiry).format("DD-MM-YYYY HH:mm")}
                                    </Typography>
                                    <Typography variant="subtitle2" sx={{ color: "text.primary" }}>
                                        Amount Type
                                    </Typography>
                                    <Typography variant="body1" sx={{ color: "text.secondary" }}>
                                        {pageDetails?.amount_type === "cust_decide"
                                            ? "customer decide"
                                            : pageDetails?.amount_type}
                                    </Typography>
                                    <Typography variant="subtitle2" sx={{ color: "text.primary" }}>
                                        Created At
                                    </Typography>
                                    <Typography variant="body1" sx={{ color: "text.secondary" }}>
                                        {moment.unix(pageDetails?.created_at).format("DD-MM-YYYY HH:mm")}
                                    </Typography>
                                    <Typography variant="subtitle2" sx={{ color: "text.primary" }}>
                                        Contact Us Email
                                    </Typography>
                                    <Typography variant="body1" sx={{ color: "text.secondary" }}>
                                        {pageDetails?.contact_us_email}
                                    </Typography>
                                </Box>
                            </AccountStyle>
                        </Box>

                        {pageDetails?._id ? (
                            <Box>
                                <AccountStyle>
                                    <PagePayments pageId={pageDetails?._id} dispatch={dispatch} useSelector={useSelector} />
                                </AccountStyle>
                            </Box>
                        ) : null}
                    </>
                ) : null}
            </Container>

            <Modal
                open={open}
                onClose={handleClose}
                style={{
                    width: "100%",
                    display: "grid",
                    placeItems: "center",
                    overflow: "scroll",
                    margin: "40px 0",
                }}
            >
                <>
                    <SharePageModalContent id={id} dispatch={dispatch} handleClose={handleClose} />
                </>
            </Modal>
        </Page>
    );
}
