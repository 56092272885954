import { unAuthorizedPost, post } from "services";

export const apiCall = async (query, variables = {}) => {
    try {
        let apiResponse = await unAuthorizedPost("", {
            query: query,
            variables: variables,
        });

        if (apiResponse.data.customStatus) {
            return {
                status: true,
                data: apiResponse.data.data,
            };
        }
        return { status: false, data: {} };
    } catch (err) {
        return { status: false, data: {} };
    }
};

export const apiCallAuthorized = async (query, variables = {}) => {
    try {
        let apiResponse = await post("", {
            query: query,
            variables: variables,
        });

        if (apiResponse.data.customStatus) {
            return {
                status: true,
                data: apiResponse.data.data,
            };
        }
        return { status: false, data: {} };
    } catch (err) {
        return { status: false, data: {} };
    }
};
