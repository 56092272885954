import { TOGGLE_LOADING, TOGGLE_SIDEBAR, TOGGLE_MODAL } from "../types";

export const toggleLoading = (data) => {
    return async (dispatch) => {
        dispatch({ type: TOGGLE_LOADING, payload: data });
    };
};

export const toggleSidebar = (data) => {
    return async (dispatch) => {
        dispatch({ type: TOGGLE_SIDEBAR, payload: data });
    };
};

export const toggleModal = (data) => {
    return async (dispatch) => {
        dispatch({ type: TOGGLE_MODAL, payload: data });
    };
};
