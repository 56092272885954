import { toast } from "react-toastify";
import { unAuthorizedPost } from "services";
// import { history } from "utils";
import { LOGIN_SUCCESS, LOGOUT, TOGGLE_LOADING } from "../types";
import { LOGIN_WITH_OTP } from "graphql";

export const loginUser = (requestData, isForRegister = false) => {
    return async (dispatch) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });

        let apiResponse = await unAuthorizedPost("", {
            query: LOGIN_WITH_OTP,
            variables: requestData,
        });

        if (apiResponse.data.customStatus) {
            if (!isForRegister) {
                let {
                    login_with_otp: { token },
                } = apiResponse.data.data;

                dispatch({ type: LOGIN_SUCCESS, payload: token });
                dispatch({ type: TOGGLE_LOADING, payload: false });
                // history.push("/dashboard/app");
            } else {
                toast.success("Business account activated");
                let {
                    login_with_otp: { token },
                } = apiResponse.data.data;

                dispatch({ type: LOGIN_SUCCESS, payload: token });
                dispatch({ type: TOGGLE_LOADING, payload: false });
                // history.push("/");
            }
        }

        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};

export const logoutUser = () => {
    return (dispatch) => {
        dispatch({ type: LOGOUT });
    };
};
