import * as Yup from 'yup';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Stack } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import { FormProvider, RHFTextField } from '../../../components/hook-form';
import { toggleLoading, apiCall } from 'store/actions';
import { REGISTER_NEW_MERCHANT } from 'graphql';

// ----------------------------------------------------------------------

export default function RegisterForm({ dispatch, nextPage, updateEmail }) {

  const RegisterSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
  });

  const defaultValues = {
    name: '',
    email: '',
    phone: '',
  };

  const methods = useForm({
    resolver: yupResolver(RegisterSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (data) => {
    dispatch(toggleLoading(true));
    updateEmail(data.email);

    const response = await apiCall(REGISTER_NEW_MERCHANT, data);
    if (!response.status) {
      dispatch(toggleLoading(false));
      return;
    }

    nextPage(1);
    dispatch(toggleLoading(false));
    return;
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        <RHFTextField name="name" label="Name" />
        <RHFTextField name="email" label="Email address" />
        <RHFTextField name="phone" label="Phone number" />

        <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
          Register
        </LoadingButton>
      </Stack>
    </FormProvider>
  );
}
