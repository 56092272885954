import { 
    LIST_API_KEY_DATA, 
    LIST_MERCHANT_PROFILE, 
    RESET_API_KEY_DATA, 
    RESET_MERCHANT_PROFILE 
} from "../types";

const INITIAL_STATE = {
    profile: {},
    apiKey: '',
};

const merchantReducer = (state = INITIAL_STATE, { type, payload }) => {
    switch (type) {
        case LIST_MERCHANT_PROFILE:
            return {
                ...state,
                profile: payload,
            };

        case RESET_MERCHANT_PROFILE:
            return {
                ...state,
                profile: INITIAL_STATE.profile,
            };

        case LIST_API_KEY_DATA:
            return {
                ...state,
                apiKey: payload,
            };

        case RESET_API_KEY_DATA:
            return {
                ...state,
                apiKey: INITIAL_STATE.apiKey,
            };
        default:
            return state;
    }
};

export default merchantReducer;