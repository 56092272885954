import { toast } from "react-toastify";
import { post } from "services";
import {
    LIST_PLINKS_DATA,
    RESET_PLINKS_LIST_DATA,
    TOGGLE_LOADING,
    RESET_PLINK_DETAILS_DATA,
    LIST_PLINK_DETAILS_DATA,
} from "../types";
import { FETCH_ALL_PLINKS, CREATE_NEW_PLINK, FETCH_PLINK_DETAILS, SHARE_PLINK } from "graphql";

export const listAllPlinks = (requestData) => {
    return async (dispatch, getState) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });
        const { pageSize, pageNumber, list } = getState().plinks;

        let apiResponse = await post("", {
            query: FETCH_ALL_PLINKS,
            variables: {
                pageSize: pageSize,
                pageNumber: pageNumber,
                status: requestData?.status ?? "",
            },
        });

        if (apiResponse.data.customStatus) {
            let {
                list_all_payment_links: { data, hasMore },
            } = apiResponse.data.data;

            let updatedList = [...list, ...data];

            dispatch({
                type: LIST_PLINKS_DATA,
                payload: {
                    list: updatedList,
                    showViewMore: hasMore,
                    pageNumber: pageNumber + 1,
                },
            });
        }

        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};

export const resetPlinksData = () => {
    return async (dispatch) => {
        dispatch({ type: RESET_PLINKS_LIST_DATA });
    };
};

export const createNewPlink = (requestData) => {
    return async (dispatch) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });

        let apiResponse = await post("", {
            query: CREATE_NEW_PLINK,
            variables: requestData,
        });

        if (apiResponse.data.customStatus) {
            dispatch(resetPlinksData());
            dispatch(listAllPlinks());
        }

        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};

export const resetPlinkDetailsData = () => {
    return async (dispatch) => {
        dispatch({ type: RESET_PLINK_DETAILS_DATA });
    };
};

export const listPlinkDetails = (requestData) => {
    return async (dispatch, getState) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });

        let apiResponse = await post("", {
            query: FETCH_PLINK_DETAILS,
            variables: requestData,
        });

        if (apiResponse.data.customStatus) {
            let {
                list_payment_link_details: { data },
            } = apiResponse.data.data;

            dispatch({
                type: LIST_PLINK_DETAILS_DATA,
                payload: data,
            });
        }

        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};

export const sharePlinkToEmail = (requestData) => {
    return async (dispatch) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });

        let apiResponse = await post("", {
            query: SHARE_PLINK,
            variables: requestData,
        });

        if (apiResponse.data.customStatus) {
            toast.success("Payment Link shared");
        }

        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};
