import { LIST_HOMEPAGE_REPORTS, RESET_HOMEPAGE_DATA } from "../types";

const INITIAL_STATE = {
    reports: {},
};

const homepageReducer = (state = INITIAL_STATE, { type, payload }) => {
    switch (type) {
        case LIST_HOMEPAGE_REPORTS:
            return {
                ...state,
                reports: payload,
            };

        case RESET_HOMEPAGE_DATA:
            return INITIAL_STATE;
        default:
            return state;
    }
};

export default homepageReducer;
