/*eslint-disable*/
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { resetPlinksData, listAllPlinks } from 'store/actions';
import CopyToClipboard from 'react-copy-to-clipboard';
import moment from 'moment';
// material
import {
  Card,
  Table,
  Stack,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Tooltip,
  Modal,
  // Checkbox,
  // Avatar,
  // Box,
  // Grid
} from '@mui/material';
// components
import Page from '../../components/Page';
import Label from '../../components/Label';
import Scrollbar from '../../components/Scrollbar';
import Iconify from '../../components/Iconify';
import SearchNotFound from '../../components/SearchNotFound';
import { UserListHead, UserListToolbar } from '../../sections/@dashboard/user';
import NewLinkModalContent from './newLinkModalContent';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'url', label: 'Link', alignRight: false },
  { id: 'amount', label: 'Amount', alignRight: false },
  { id: 'expiry', label: 'Expiry', alignRight: false },
  { id: 'payment_for', label: 'Purpose', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: '' },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.url?.toLowerCase().indexOf(query?.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function PaymentLinks() {
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('desc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('index');
  const [filterName, setFilterName] = useState('');

  const [hoveredLink, setHoveredLink] = useState("");
  const [copied, setCopied] = useState(false);

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { plinksData, showViewMore } = useSelector(({ plinks: { list, showViewMore } }) => ({
    plinksData: list,
    showViewMore
  }));
  
  useEffect(() => {
    dispatch(resetPlinksData());
    fetchMoreData();
  }, []);

  const fetchMoreData = () => {
    dispatch(listAllPlinks());
  };

  const handleHover = (data = "") => {
    setHoveredLink(data);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = plinksData.map((n) => n.url);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const filteredUsers = applySortFilter(plinksData, getComparator(order, orderBy), filterName);

  const isUserNotFound = filteredUsers.length === 0;

  return (
    <Page title="Payment Links">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Payment Links
          </Typography>
          <Button variant="contained" onClick={handleOpen} startIcon={<Iconify icon="eva:plus-fill" />}>
            New Payment Link
          </Button>
        </Stack>

        <Card>
          <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={plinksData.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {filteredUsers.map((row, index) => {
                    const { url, expiry, status, amount, currency, payment_for, plink_id } = row;
                    const isItemSelected = selected.indexOf(url) !== -1;

                    return (
                      <TableRow
                        hover
                        key={index}
                        tabIndex={-1}
                        role="checkbox"
                        selected={isItemSelected}
                        aria-checked={isItemSelected}
                        onClick={() => navigate(`/dashboard/plink-details/${plink_id}`)}
                      >
                        <TableCell padding="checkbox">
                          {/* <Button sx={{ mb: 1 }} component={RouterLink} to={`/dashboard/plink-details/${plink_id}`} startIcon={<Iconify icon="eva:arrow-forward-fill" />} /> */}
                          {/* <Checkbox checked={isItemSelected} onChange={(event) => handleClick(event, url)} /> */}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {/* <Avatar alt={url} src={avatarUrl} /> */}
                          <Typography variant="subtitle2" noWrap>
                            <div onMouseOver={() => handleHover(url)} onMouseOut={() => setCopied(false)}>
                              <>
                                <CopyToClipboard text={hoveredLink} onCopy={() => setCopied(true)}>
                                  {hoveredLink === url ? (
                                    <Tooltip title={copied ? "copied" : "click to copy"}>
                                      <p>{url.replace("https://", "")}</p>
                                    </Tooltip>
                                  ) : (
                                    <p>{url.replace("https://", "")}</p>
                                  )}
                                </CopyToClipboard>
                              </>
                            </div>
                          </Typography>
                        </TableCell>
                        <TableCell align="left">
                          <Typography noWrap>
                            {amount} {currency.toUpperCase()}
                          </Typography>
                        </TableCell>
                        <TableCell align="left">
                          <Typography noWrap>
                            {expiry === 0 ? "No expiry" :
                              moment.unix(expiry).format("DD-MM-YYYY HH:mm")}
                          </Typography>
                        </TableCell>
                        <TableCell align="left">{payment_for ? payment_for : '-'}</TableCell>
                        <TableCell align="left">
                          <Label variant="ghost"
                            color={
                              (status === 'expired' && 'error') ||
                              (status === 'paid' && 'success') ||
                              (status === 'created' && 'primary') ||
                              'default'
                            }>
                            {sentenceCase(status)}
                          </Label>
                        </TableCell>

                        <TableCell align="right">
                          {/* <UserMoreMenu id={plink_id}/> */}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>

                {isUserNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>
          {showViewMore && (
            <Stack justifyContent='center'>
              <Button onClick={fetchMoreData}>
                Show more
              </Button>
            </Stack>
          )}
          {/* <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={plinksData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          /> */}
        </Card>
      </Container>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{
          width: "100%",
          display: "grid",
          placeItems: "center",
          overflow: "scroll",
          margin: "20px 0"
        }}
      >
        <>
          <NewLinkModalContent dispatch={dispatch} handleClose={handleClose} />
        </>
      </Modal>
    </Page>
  );
}
